import { useEffect, createRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import card1Icon1 from '../../../images/card1Icon1.png';
import card1Icon2 from '../../../images/card1Icon2.png';
import card1Icon3 from '../../../images/card1Icon3.png';
import functionIcon from '../../../images/functionIcon.png';
import functionsImage from '../../../images/functionsImage.png';
import appsBg from '../../../images/appsBg.png';
import devCommunityRightImage from '../../../images/devCommunityRightImage.png';
import card2Bg1 from '../../../images/card2Bg1.png';
import card2Bg2 from '../../../images/card2Bg2.png';
import card2Bg3 from '../../../images/card2Bg3.png';
import card2Icon1 from '../../../images/card2Icon1.png';
import card2Icon1Black from '../../../images/card2Icon1Black.png';
import card2Icon2 from '../../../images/card2Icon2.png';
import card2Icon2Black from '../../../images/card2Icon2Black.png';
import card2Icon3 from '../../../images/card2Icon3.png';
import card2Icon3Black from '../../../images/card2Icon3Black.png';
import appsSlider from '../../../images/appsSlider.png';
import devCommunityBg from '../../../images/devCommunityBg.png';
import devCommunityLeftBg from '../../../images/devCommunityLeftBg.png';
import appsBgMobile from '../../../images/appsBgMobile.png';
import appsSliderMobile from '../../../images/appsSliderMobile.png';
import Card1 from './Card1';
import Card2 from './Card2';
import './Content.css';
import { setShowIfNeeded, throttle, report } from '../../../utils';
import urlManager from '../../../urlManager';

function Content() {
    const card1ListRef = createRef<HTMLDivElement>();
    const functionsRef = createRef<HTMLDivElement>();
    const appsRef = createRef<HTMLDivElement>();
    const devCommunityRef = createRef<HTMLDivElement>();
    const moreToolsRef = createRef<HTMLDivElement>();
    const forwardRef = createRef<HTMLDivElement>();

    useEffect(() => {
        const onScroll = throttle(() => {
            setShowIfNeeded(card1ListRef.current!, -10);
            setShowIfNeeded(functionsRef.current!);
            setShowIfNeeded(appsRef.current!);
            setShowIfNeeded(devCommunityRef.current!);
            setShowIfNeeded(moreToolsRef.current!);
            setShowIfNeeded(forwardRef.current!);
        }, 150);
        const intervalId = setInterval(onScroll, 150);
        return () => {
            console.log('setInterval?')
            clearInterval(intervalId);
        };
    }, []);

    return <div className="content">
        <div className="card1List" ref={card1ListRef}>
            <Card1 icon={card1Icon1} title={intl.get('title_card1Title1')} desc={intl.get('desc_card1Desc1')}></Card1>
            <Card1 icon={card1Icon2} title={intl.get('title_card1Title2')} desc={intl.get('desc_card1Desc2')}></Card1>
            <Card1 icon={card1Icon3} title={intl.get('title_card1Title3')} desc={intl.get('desc_card1Desc3')}></Card1>
        </div>
        <div className="functions" ref={functionsRef}>
            <div className="function_title">{intl.get('title2')}</div>
            <div className="functions_content">
                <div className="functions_left">
                    <div className="function_line">
                        <div className="function"><img src={functionIcon} alt="" /><span>{intl.get('function1')}</span></div>
                        <div className="function"><img src={functionIcon} alt="" /><span>{intl.get('function2')}</span></div>
                    </div>
                    <div className="function_line">
                        <div className="function"><img src={functionIcon} alt="" /><span>{intl.get('function3')}</span></div>
                        <div className="function"><img src={functionIcon} alt="" /><span>{intl.get('function4')}</span></div>
                    </div>
                    <div className="function_line">
                        <div className="function"><img src={functionIcon} alt="" /><span>{intl.get('function5')}</span></div>
                        <div className="function"><img src={functionIcon} alt="" /><span>{intl.get('function6')}</span></div>
                    </div>
                    <a href={urlManager.get('checkoutDoc')} target="_blank" className="checkout_devdoc" onClick={() => report('home_viewdock_click')}>{intl.get('checkout_doc')}</a>
                </div>
                <img src={functionsImage} alt="" className="functions_image" />
            </div>
        </div>
        <div className="apps" ref={appsRef}>
            <div className="apps_container">
                <div className="apps_left">
                    <div className="apps_title"><span className="apps_title_num">1500+</span>{intl.get('title3')}</div>
                    <img src={appsBg} alt="" className="apps_bg" />
                    <img src={appsBgMobile} alt="" className="apps_bg_mobile" />
                </div>
                <div className="apps_rightSlider">
                    <div className="apps_rightSlider_imgContainer">
                        <img src={appsSlider} alt="" className="apps_rightSlider_imgContainer_img" />
                        <img src={appsSlider} alt="" className="apps_rightSlider_imgContainer_img" />
                    </div>
                    <div className="apps_rightSlider_imgContainer_mobile">
                        <img src={appsSliderMobile} alt="" className="apps_rightSlider_imgContainer_img_mobile" />
                        <img src={appsSliderMobile} alt="" className="apps_rightSlider_imgContainer_img_mobile" />
                    </div>
                </div>
            </div>
        </div>
        <div className="devCommunity">
            <div className="devCommunity_container">
                <img src={devCommunityLeftBg} alt="" className="devCommunity_leftBg" />
                <div className="devCommunity_left" ref={devCommunityRef}>
                    <div className="devCommunity_title">{intl.get('title4')}</div>
                    <div className="devCommunity_desc">{intl.get('desc4')}</div>
                    <Link to="/community" className="join_community" onClick={() => report('home_joincommunity_click')}>{intl.get('join_community')}</Link>
                </div>
                <div className="devCommunity_right">
                    <img src={devCommunityRightImage} alt="" className="devCommunity_rightImage" />
                </div>
            </div>
            <img src={devCommunityBg} alt="" className="devCommunity_bg" />
        </div>
        <div className="moreTools" ref={moreToolsRef}>
            <div className="moreTools_title">{intl.get('title5')}</div>
            <div className="moreTools_cardList">
                <Card2
                    icon={card2Icon1}
                    iconB={card2Icon1Black}
                    title="TronBox"
                    desc={intl.get('desc_card2Desc1')}
                    bg={card2Bg1}
                    link={`${urlManager.get('tronbox')}${window.location.search}`}
                    event="home_tronbox_click"
                ></Card2>
                <Card2
                    icon={card2Icon2}
                    iconB={card2Icon2Black}
                    title="Tron-IDE"
                    desc={intl.get('desc_card2Desc2')}
                    bg={card2Bg2}
                    link={urlManager.get('tronide')}
                    event="home_ide_click"
                ></Card2>
                <Card2
                    icon={card2Icon3}
                    iconB={card2Icon3Black}
                    title="TronWallet-Adapter"
                    desc={intl.get('desc_card2Desc3')}
                    bg={card2Bg3}
                    link={urlManager.get('walletAdapter')}
                    event="home_adapter_click"
                ></Card2>
            </div>
        </div>
        <div className="forward" ref={forwardRef}>
            <div className="forward_title">{intl.get('title6')}</div>
            <div className="forward_desc">{intl.get('desc6')}</div>
        </div>
    </div>;
}

export default Content;