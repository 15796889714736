import axios from 'axios';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useStore } from '../StoreProvider';
import styles from './style.module.css';
import intl from 'react-intl-universal';

const instance = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  }
});
const getTipStatus = async () => {
  try {
    await instance.get('https://tjsk.tronweb.network/?time=' + Date.now());
  } catch (error) {
    if (error.code) {
      return true;
    }
  }
};
export default function MainLandServiceAlert() {
  const { showServiceAlert, setShowServiceAlert } = useStore();

  useEffect(() => {
    getTipStatus().then(res => {
      setShowServiceAlert(!!res);
    });
  }, [setShowServiceAlert]);

  function handleClick() {
    setShowServiceAlert(false);
  }

  return (
    <div className={styles.serviceAlert} style={{ display: showServiceAlert ? 'flex' : 'none' }}>
      <p className={styles.p}>
        <span className={styles.icon}></span>
        <span className={styles.text}>{intl.get('serviceAlert')}</span>
      </p>
      <span className={styles.button} onClick={handleClick}>
        {intl.get('serviceAlertGotit')}
      </span>
    </div>
  );
}
