import { useEffect, createRef } from 'react';
import intl from 'react-intl-universal';
import HeaderBar from '../../../components/HeaderBar';
import communityHeaderBg from '../../../images/communityHeaderBg.png';
import communityLeftBg from '../../../images/communityLeftBg.png';
import communityHeaderBgMobile from '../../../images/communityHeaderBgMobile.png';
import './Header.css';

function Header() {
  const communityHeaderContainerRef = createRef<HTMLDivElement>();
  useEffect(() => {
    setTimeout(() => {
      communityHeaderContainerRef.current?.classList.add('show');
    }, 150);
  }, []);

  return (
    <div className="community_header">
      <HeaderBar current="community"></HeaderBar>
      <div className="community_header_container" ref={communityHeaderContainerRef}>
        <div className="community_banner">
          <div className="community_title">{intl.get('community_title1')}</div>
          <div className="community_desc">{intl.get('community_desc1')}</div>
        </div>
        <img src={communityLeftBg} alt="" className="communityLeftBg" />
        <img src={communityHeaderBg} alt="" className="community_header_bg" />
        <img src={communityHeaderBgMobile} alt="" className="community_header_bg_mobile" />
      </div>
    </div>
  );
}

export default Header;
