import { useEffect, createRef } from 'react';
import intl from 'react-intl-universal';
import './Header.css';
import HeaderBar from '../../../components/HeaderBar';
import homepageBannerBg from '../../../images/homepageBannerBg.png';
import homepageBannerBgMobile from '../../../images/homepageBannerBgMobile.png';
import urlManager from '../../../urlManager';
import { report } from '../../../utils';

function Header() {
  const headerContentRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setTimeout(() => {
      headerContentRef.current?.classList.add('show');
    }, 150);
  }, []);

  return (
    <div className="header">
      <HeaderBar current="homepage"></HeaderBar>
      <div className="headerContent" ref={headerContentRef}>
        <h1 className="title">TronWeb</h1>
        <div className="titleDesc">{intl.get('titleDesc')}</div>
        <div className="mainButtons">
          <a
            href={urlManager.get('start')}
            target="_blank"
            className="mainButton_start"
            onClick={() => report('home_start_click')}
          >
            {intl.get('start')}
          </a>
          <a
            href={urlManager.get('toGithub1')}
            target="_blank"
            className="mainButton_toGithub"
            onClick={() => report('home_gotogithub_click')}
          >
            {intl.get('toGithub')}
          </a>
        </div>
        <div className="usage">
          <div className="downloads">
            <div className="usage_num">40,000+</div>
            <div className="usage_desc">{intl.get('weeklyDownload')}</div>
          </div>
          <div className="projects">
            <div className="usage_num">1,500+</div>
            <div className="usage_desc">{intl.get('projectUsage')}</div>
          </div>
        </div>
        <img src={homepageBannerBg} alt="" className="homepage_bannerBg" />
        <img src={homepageBannerBgMobile} alt="" className="homepage_bannerBg_mobile" />
      </div>
    </div>
  );
}

export default Header;
