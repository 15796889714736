import { createContext, FC, ReactElement, useContext, useState } from 'react';
interface RootState {
  showServiceAlert: boolean;
  setShowServiceAlert: (v: boolean) => void;
}
const rootState: RootState = {
  showServiceAlert: false,
  setShowServiceAlert: () => {}
};
const Context = createContext(rootState);
export const StoreProvider: FC<{ children: ReactElement }> = function (props) {
  const [showServiceAlert, setShowServiceAlert] = useState(false);
  return (
    <Context.Provider
      value={{
        showServiceAlert,
        setShowServiceAlert
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useStore = function () {
  return useContext(Context);
};
