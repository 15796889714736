import intl from 'react-intl-universal';
import goDetailRightArrow from '../../../images/goDetailRightArrow.png';
import './Card2.css';
import { report } from '../../../utils';

interface Card2Props {
    icon: string;
    iconB: string;
    title: string;
    desc: string;
    bg: string;
    link: string;
    event: string;
}

function Card2(props: Card2Props) {
    return <div className="card2">
        <div className="card2_header">
            <img src={props.icon} alt="" className="card2_icon" />
            <img src={props.iconB} alt="" className="card2_icon_black" />
            <div className="card2_title">{props.title}</div>
        </div>
        <div className="card2_desc">{props.desc}</div>
        <a href={props.link} target="_blank" className="card2_goDetail" onClick={() => report(props.event)}>{intl.get('go_detail')} <img src={goDetailRightArrow} alt="" className="card2_goDetail_rightArrow" /></a>
        <img src={props.bg} alt="" className="card2_bg" />
    </div>
}

export default Card2;