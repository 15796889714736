import intl from 'react-intl-universal';
import urlData from './URL_DATA.json';

type UrlDataType = typeof urlData;
type LocaleTypes = keyof UrlDataType;
type ValueOf<T> = T[keyof T];
type UrlDataValues = keyof ValueOf<typeof urlData>

export default {
    get(key: UrlDataValues) {
        const lang = intl.getInitOptions().currentLocale as LocaleTypes;
        return urlData[lang][key];
    },
    go(key: UrlDataValues) {
        window.open(this.get(key), '_blank');
    }
}