import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Homepage from './pages/index/App';
import Community from './pages/community/index';
import reportWebVitals from './reportWebVitals';
import initIntl from './initIntl';

import { createHashRouter, RouterProvider } from 'react-router-dom';
import { StoreProvider } from './components/StoreProvider';

const router = createHashRouter([
  {
    path: '/',
    element: <Homepage />
  },
  {
    path: '/community',
    element: <Community></Community>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initIntl();
