import intl from 'react-intl-universal';
import LOCALE_DATA from './LOCALE_DATA.json';

export const LOCALES_LIST = [
    {
        lang: 'en-US',
        label: 'English',
    },
    // {
    //     lang: 'zh-CN',
    //     label: '简体中文',
    // },
    {
        lang: 'zh-TC',
        label: '繁體中文',
    },
];

function initIntl() {
    intl.init({
        currentLocale: (intl as any).getLocaleFromURL({
            urlLocaleKey: 'lang',
        }) || 'en-US',
        locales: {
            'en-US': LOCALE_DATA['en-US'],
            'zh-TC': LOCALE_DATA['zh-TC'],
        },
    });
}

export default initIntl;