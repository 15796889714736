import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import IconDropdown from '../images/icon_dropdown.svg';
import './HeaderBar.css';
import intl from 'react-intl-universal';
import { LOCALES_LIST } from '../initIntl';
import { report, throttle } from '../utils';
import urlManager from '../urlManager';
import headerBarMenuIcon from '../images/menu.png';
import headerBarCloseIcon from '../images/close.png';
import MainLandServiceAlert from './MainlandServiceAlert';

interface HeaderBarProps {
  current: 'homepage' | 'community';
}

function HeaderBar(props: HeaderBarProps) {
  const [currentLangName, setCurrentLangName] = useState('');
  const headerBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const _currentLangName = LOCALES_LIST.find(locale => locale.lang === intl.getInitOptions().currentLocale)?.label;
    setCurrentLangName(_currentLangName!);

    const onScroll = throttle(() => {
      if (window.scrollY > 0) {
        if (!headerBarRef.current?.classList.contains('scrolling')) {
          headerBarRef.current?.classList.add('scrolling');
        }
      } else {
        if (headerBarRef.current?.classList.contains('scrolling')) {
          headerBarRef.current.classList.remove('scrolling');
        }
      }
    }, 150);
    // window.addEventListener('scroll', onScroll);
    const intervalId = setInterval(onScroll, 150);
    return () => {
      // window.removeEventListener('scroll', onScroll);
      clearInterval(intervalId);
    };
  }, []);

  function showMenu() {
    if (!headerBarRef.current?.classList.contains('mobileShow')) {
      headerBarRef.current?.classList.add('mobileShow');
    }
  }

  function closeMenu() {
    if (headerBarRef.current?.classList.contains('mobileShow')) {
      headerBarRef.current.classList.remove('mobileShow');
    }
  }

  return (
    <div className="headerBar" ref={headerBarRef}>
      <MainLandServiceAlert />

      <div className="headerBar_container">
        <Link to="/">
          <img className="logo" src={Logo} alt="" />
        </Link>
        <div className="nav">
          <Link
            to="/"
            className={`nav_item ${props.current === 'homepage' ? 'current' : ''}`}
            onClick={() => report('header_click')}
          >
            {intl.get('homepage')}
          </Link>
          <a
            onClick={() => report('event', 'header_developerdock_click')}
            href={urlManager.get('devdoc')}
            target="_blank"
            className="nav_item"
          >
            {intl.get('devdoc')}
          </a>
          <Link
            to="/community"
            className={`nav_item ${props.current === 'community' ? 'current' : ''}`}
            onClick={() => report('header_communitypage_click')}
          >
            {intl.get('community')}
          </Link>
        </div>
        <div className="langSelector">
          <div className="selectedLang">
            {currentLangName} <img className="selectedLang_arrow" src={IconDropdown} alt="" />
          </div>
          <div className="langList">
            {LOCALES_LIST.map(locale => (
              <a key={locale.lang} href={`?lang=${locale.lang}${window.location.hash}`} className="lang">
                {locale.label}
              </a>
            ))}
          </div>
        </div>
        <img src={headerBarMenuIcon} alt="" className="headerBar_mobile_menu" onClick={showMenu} />
        <img src={headerBarCloseIcon} alt="" className="headerBar_mobile_close" onClick={closeMenu} />
      </div>
    </div>
  );
}

export default HeaderBar;
