import { useState, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import Github from '../images/iconGithub.svg';
import Telegram from '../images/iconTelegram.svg';
import Discord from '../images/iconDiscord.svg';
import './FooterBar.css';
import urlManager from '../urlManager';
import { report } from '../utils';

function FooterBar() {
    const [telegramHref, setTelegramHref] = useState('' as string | undefined);
    const footerBarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        switch (intl.getInitOptions().currentLocale) {
            case 'zh-CN':
            case 'zh-TC':
                setTelegramHref(undefined);
                break;
            case 'en-US':
                setTelegramHref(urlManager.get('groupEn'));
        }

        if (document.body.scrollHeight < window.screen.availHeight) {
            footerBarRef.current?.classList.add('stickyToBottom')
        } else {
            footerBarRef.current?.classList.remove('stickyToBottom')
        }
    }, []);

    return <div className="footerBar" ref={footerBarRef}>
        <div className="footerBar_container">
            <div className="footerBar_left">
                <Link to="/"><img src={Logo} alt="" className="footerBar_logo" /></Link>
                <a href={urlManager.get('devdoc2')} target="_blank" className="footerBar_devDoc" onClick={() => report('footer_developerdock_click')}>{intl.get('devdoc')}</a>
            </div>
            <div className="footerBar_right">
                <a href={urlManager.get('toGithub2')} target="_blank" className="footerBar_icon" onClick={() => report('footer_github_click')}>
                    <img src={Github} alt="" className="footerBar_iconImage" />
                </a>
                <a href={telegramHref} target="_blank" className="footerBar_icon footerBar_telegramIcon" onClick={() => report('footer_telegram_click')}>
                    <img src={Telegram} alt="" className="footerBar_iconImage" />
                    {
                        !telegramHref && <div className="telegramChinaList">
                            <a href={urlManager.get('group1')} target="_blank" className="telegramChinaList_item" onClick={() => report('footer_telegram_click')}>{intl.get('devGroup1')}</a>
                            <a href={urlManager.get('group2')} target="_blank" className="telegramChinaList_item" onClick={() => report('footer_telegram_click')}>{intl.get('devGroup2')}</a>
                        </div>
                    }
                </a>
                <a href={urlManager.get('discord')} target="_blank" className="footerBar_icon" onClick={() => report('footer_discord_click')}>
                    <img src={Discord} alt="" className="footerBar_iconImage" />
                </a>
            </div>
        </div>
    </div>
}

export default FooterBar;