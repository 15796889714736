import React from 'react';
import './Card1.css';

interface Card1Props {
    icon: string;
    title: string;
    desc: string;
}

function Card1(props: Card1Props) {
    return <div className="card1">
        <div className="card1_icon">
            <img src={props.icon} alt="" />
        </div>
        <div className="card1_content">
            <div className="card1_title">{props.title}</div>
            <div className="card1_desc">{props.desc}</div>
        </div>
    </div>;
}

export default Card1;