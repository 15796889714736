export function setShowIfNeeded(element: HTMLElement, shreshold: number = 200) {
    if (!element) return;
    const rect = element.getBoundingClientRect();
    if (window.innerHeight - rect.top > shreshold) {
        if (!element.classList.contains('show')) {
            element.classList.add('show');
        }
    }
}

export const throttle = (fn: Function, timeGap: number) => {
    let lastRunTime = 0;
    return (...args: any[]) => {
        if (Date.now() - lastRunTime >= timeGap) {
            fn(...args);
            lastRunTime = Date.now();
        }
    };
};

export const report = (...args: any[]) => {
    const gtag = (window as any).gtag || function() {};
    try {
        gtag(...args);
    } catch(e) {
        console.error('ga is not ready');
    }
};
